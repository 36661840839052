import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import axios from "axios";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import styles from "assets/jss/material-kit-pro-react/components/tableStyle.js";
import configData from "assets/json/config.json";

class InfoTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stock: this.props.stock,
      stockName: "",
      stockHistPrice: [],
      tableHead: ["Stock Name", "Industry"],
      tableHeaderColor: "gray",
      hover: true,
      colorsColls: [],
      coloredColls: [],
      striped: false,
      customCellClasses: [],
      customClassesForCells: [],
      customHeadCellClasses: [],
      customHeadClassesForCells: [],
      greatReturns: "",
      efficientCashflow: "",
      moneyValue: "",
    };
    this.getInfo = this.getInfo.bind(this);
    this.createData = this.createData.bind(this);
    this.getGemScore = this.getGemScore.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stock !== this.props.stock) {
      this.getInfo(this.props.stock);
      this.getGemScore(this.props.stock);
    }
  }

  // Get GEM score
  getGemScore(stock) {
    axios
      .get(configData.GEM_SCORE, {
        withCredentials: false,
        params: {
          stock: stock,
        },
      })
      .then((res) => {
        var returns = 0;
        var cashflow = 0;
        var moneyValue = 0;
        if (
          typeof res !== "undefined" &&
          typeof res.data !== "undefined" &&
          typeof res.data.stockInfo !== "undefined"
        ) {
          returns = res.data.stockInfo["Great Return"].toFixed(2);
          cashflow = res.data.stockInfo["Efficient Cashflow"];
          if (cashflow > 0) {
            cashflow = "Positive";
          } else {
            cashflow = "Negative";
          }
          moneyValue = res.data.stockInfo["Money Value"].toFixed(2);
        } else {
          returns = "NA";
          cashflow = "NA";
          moneyValue = "NA";
        }

        this.setState({
          greatReturns: returns, // Great Returns
          efficientCashflow: cashflow, // Efficient Cashflow
          moneyValue: moneyValue, // Efficient Cashflow
        });
      });
  }

  // Get Fundamentals
  getInfo(stock) {
    axios
      .get(configData.SM_FUNDAMENTALS, {
        withCredentials: false,
        params: {
          stock: stock,
        },
      })
      .then((res) => {
        this.setState({
          stockIndex: res.data.stockInfo["Index"], // Index
          stockName: res.data.stockInfo["Company"], // Company Name
          industry: res.data.stockInfo["Industry"], // Industry
          country: res.data.stockInfo["Country"], // Country
          marketCap: res.data.stockInfo["Market Cap"], // Market Cap
          dividendYield: res.data.stockInfo["Dividend %"], // Dividend Yield
          shortFloat: res.data.stockInfo["Shs Float"], // Short Float
          optionable: res.data.stockInfo["Optionable"], // Optionable
          avgVolume: res.data.stockInfo["Avg Volume"], // Average Volume
          relVolume: res.data.stockInfo["Rel Volume"], // Relative Volume
          volume: res.data.stockInfo["Volume"], // Volume
          price: res.data.stockInfo["Price"], // Price
          targetPrice: res.data.stockInfo["Target Price"], // Target Price
          shsOutstanding: res.data.stockInfo["Shs Outstand"], // Shares Outstanding
          float: res.data.stockInfo["Shs Float"],
          analystRecom: res.data.stockInfo["Recom"],
          pe: res.data.stockInfo["P/E"], // PE Ratio
          fpe: res.data.stockInfo["Forward P/E"], // Forward PE Ratio
          peg: res.data.stockInfo["PEG"], // PEG
          ps: res.data.stockInfo["P/S"], // PS Ratio
          pb: res.data.stockInfo["P/B"], // PB Ratio=
          epsY: res.data.stockInfo["EPS this Y"], // EPS this Y
          epsNY: res.data.stockInfo["EPS next Y"], // EPS Next Y
          epsP5Y: res.data.stockInfo["EPS past 5Y"], // EPS Past 5Y
          epsN5Y: res.data.stockInfo["EPS next 5Y"], // EPS Next 5Y
          salesP5Y: res.data.stockInfo["Sales past 5Y"], // Sales past 5Y
          epsQQ: res.data.stockInfo["EPS Q/Q"], // EPS quarter over quarter
          salesQQ: res.data.stockInfo["Sales Q/Q"], // Sales quarter over quarter
          roa: res.data.stockInfo["ROA"], // Return on Assets
          roe: res.data.stockInfo["ROE"], // Return on Equity
          roi: res.data.stockInfo["ROI"], // Return on Investment
          cr: res.data.stockInfo["Current Ratio"], // Current Ratio
          qr: res.data.stockInfo["Quick Ratio"], // Quick Ratio
          ltDeq: res.data.stockInfo["LT Debt/Eq"], // LT Debt/Equity
          deq: res.data.stockInfo["Debt/Eq"], // Debt/Equity
          grossMargin: res.data.stockInfo["Gross Margin"], // Gross Margin
          operateMargin: res.data.stockInfo["Oper. Margin"], // Operating Margin
          netMargin: res.data.stockInfo["Profit Margin"], // Net profit Margin
          payout: res.data.stockInfo["Payout"], // Payout ratio
          insiderOwnership: res.data.stockInfo["Insider Own"], // Insider Ownership
          insiderTrans: res.data.stockInfo["Insider Trans"], // Insider Transactions
          institutionalOwn: res.data.stockInfo["Inst Own"], // Institutional Ownership
          institutionalTrans: res.data.stockInfo["Inst Trans"], // Institutional Transactions
          perfYtd: res.data.stockInfo["Perf YTD"],
          perfWeek: res.data.stockInfo["Perf Week"],
          perfMth: res.data.stockInfo["Perf Month"],
          perfQuarter: res.data.stockInfo["Perf Quarter"],
          volatility: res.data.stockInfo["Volatility"],
          rsi_14: res.data.stockInfo["RSI (14)"],
          sma20: res.data.stockInfo["SMA20"],
          sma50: res.data.stockInfo["SMA50"],
          sma200: res.data.stockInfo["SMA200"],
          change: res.data.stockInfo["Change"],
          wk52High: res.data.stockInfo["52W High"],
          wk52Low: res.data.stockInfo["52W Low"],
          wk52Range: res.data.stockInfo["52W Range"],
          beta: res.data.stockInfo["Beta"],
          atr: res.data.stockInfo["ATR"],
        });
      });
  }

  createData(
    firstCol,
    secondCol,
    thirdCol,
    fourthCol,
    fifthCol,
    sixthCol,
    seventhCol,
    eighthCol
  ) {
    return {
      firstCol,
      secondCol,
      thirdCol,
      fourthCol,
      fifthCol,
      sixthCol,
      seventhCol,
      eighthCol,
    };
  }

  render() {
    const { classes } = this.props;
    const descriptiveRows = [
      this.createData(
        "Index",
        this.state.index,
        "Company",
        this.state.stockName,
        "Industry",
        this.state.industry,
        "Country",
        this.state.country
      ),
      this.createData(
        "Market Cap",
        this.state.marketCap,
        "Dividend Yield",
        this.state.dividendYield,
        "Float Short",
        this.state.shortFloat,
        "Optionable",
        this.state.optionable
      ),
      this.createData(
        "Average Volume",
        this.state.avgVolume,
        "Relative Volume",
        this.state.relVolume,
        "Volume",
        this.state.volume,
        "Price",
        this.state.price
      ),
      this.createData(
        "Target Price",
        this.state.targetPrice,
        "Shares Outstanding",
        this.state.shsOutstanding,
        "Float",
        this.state.float,
        "Analyst Recom (SB1-SS5)",
        this.state.analystRecom
      ),
    ];
    const fundamentalRows = [
      this.createData(
        "P/E",
        this.state.pe,
        "Forward P/E",
        this.state.fpe,
        "PEG",
        this.state.peg,
        "P/S",
        this.state.ps
      ),
      this.createData(
        "P/B",
        this.state.pb,
        "EPS this 1Y",
        this.state.epsY,
        "EPS next 1Y",
        this.state.epsNY,
        "EPS past 5Y",
        this.state.epsP5Y
      ),
      this.createData(
        "EPS next 5Y",
        this.state.epsN5Y,
        "Sales past 5Y",
        this.state.salesP5Y,
        "EPS quarter/quarter",
        this.state.epsQQ,
        "Sales quarter/quarter",
        this.state.salesQQ
      ),
      this.createData(
        "Return on Assets",
        this.state.roa,
        "Return on Equity",
        this.state.roe,
        "Return on Investment",
        this.state.roi,
        "Current Ratio",
        this.state.cr
      ),
      this.createData(
        "Quick Ratio",
        this.state.qr,
        "LT Debt/Equity",
        this.state.ltDeq,
        "Debt/Equity",
        this.state.deq,
        "Gross Margin",
        this.state.grossMargin
      ),
      this.createData(
        "Operating Margin",
        this.state.operateMargin,
        "Net Profit Margin",
        this.state.netMargin,
        "Payout Ratio",
        this.state.payout,
        "Insider Ownership",
        this.state.insiderOwnership
      ),
      this.createData(
        "Insider Transactions",
        this.state.insiderTrans,
        "Institutional Ownership",
        this.state.institutionalOwn,
        "Institutional Transactions",
        this.state.insiderTrans
      ),
    ];
    const technicalRows = [
      this.createData(
        "Perf Ytd",
        this.state.perfYtd,
        "Perf Week",
        this.state.perfWeek,
        "Perf Month",
        this.state.perfMth,
        "Perf Quarter",
        this.state.perfQuarter
      ),
      this.createData(
        "Volatility",
        this.state.volatility,
        "RSI (14)",
        this.state.rsi_14,
        "20D SMA",
        this.state.sma20,
        "50D SMA",
        this.state.sma50
      ),
      this.createData(
        "200D SMA",
        this.state.sma200,
        "Change",
        this.state.change,
        "52W High",
        this.state.wk52High,
        "52W Low",
        this.state.wk52Low
      ),
      this.createData(
        "52W Range",
        this.state.wk52Range,
        "Beta",
        this.state.beta,
        "Average True Range",
        this.state.atr
      ),
    ];
    const roc = this.state.greatReturns;
    const cashflow = this.state.efficientCashflow;
    const moneyValue = this.state.moneyValue;

    return (
      <div>
        <GridContainer>
          <GridItem md={4}>
            <h3
              className={classes.title}
              style={roc > 30 ? { color: "green" } : { color: "red" }}
            >
              ROC: {roc}%
            </h3>
          </GridItem>
          <GridItem md={4}>
            <h3
              className={classes.title}
              style={
                cashflow === "Positive" ? { color: "green" } : { color: "red" }
              }
            >
              Cashflow: {cashflow}
            </h3>
          </GridItem>
          <GridItem md={4}>
            <h3
              className={classes.title}
              style={moneyValue > 0 ? { color: "green" } : { color: "red" }}
            >
              Money Value: {moneyValue}%
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <NavPills
              color="primary"
              tabs={[
                {
                  tabButton: "Descriptive",
                  tabContent: (
                    <Table>
                      <TableBody>
                        {descriptiveRows.map((row) => (
                          <TableRow key={row.firstCol}>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.firstCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.secondCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.thirdCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.fourthCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.fifthCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.sixthCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.seventhCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.eighthCol}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ),
                },
                {
                  tabButton: "Fundamental",
                  tabContent: (
                    <Table>
                      <TableBody>
                        {fundamentalRows.map((row) => (
                          <TableRow key={row.firstCol}>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.firstCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.secondCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.thirdCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.fourthCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.fifthCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.sixthCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.seventhCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.eighthCol}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ),
                },
                {
                  tabButton: "Technical",
                  tabContent: (
                    <Table>
                      <TableBody>
                        {technicalRows.map((row) => (
                          <TableRow key={row.firstCol}>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.firstCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.secondCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.thirdCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.fourthCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.fifthCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.sixthCol}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "bold" }}
                            >
                              {row.seventhCol}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.eighthCol}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

InfoTable.propTypes = {
  classes: PropTypes.object.isRequired,
  stock: PropTypes.string,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
};

export default withStyles(styles)(InfoTable);
