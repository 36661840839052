import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridItem from "components/Grid/GridItem.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class TechnicalAnalysis extends Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {
      ticker: this.props.ticker,
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    const ticker = this.state.ticker;
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      colorTheme: "dark",
      isTransparent: false,
      locale: "en",
      interval: "1W",
      symbol: ticker,
      showIntervalTabs: true,
    });
    this._ref.current.appendChild(script);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.technicalAnalysis}>
            <div className="tradingview-widget-container" ref={this._ref}>
              <div className="tradingview-widget-container__widget"></div>
            </div>
          </div>
        </GridItem>
      </div>
    );
  }
}

TechnicalAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
  ticker: PropTypes.string,
};

export default withStyles(productStyle)(TechnicalAnalysis);
