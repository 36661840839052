import React from "react";
import { Route, Switch } from "react-router";
import "../node_modules/react-vis/dist/style.css";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// Pages for Albemy
import HomePage from "views/HomePage/HomePage.js";
import SMPage from "views/SMPage/LandingPage.js";
import Overview from "views/MarketOverviewPage/Overview.js";
import CryptoOverview from "views/CryptoOverview/Overview.js";
import CryptoScreener from "views/CryptoScreener/Overview.js";
import FAQ from "views/FAQPage/FAQPage.js";
import Cookies from "views/Cookies/Cookies.js";

import Loading from "views/LoginPage/Loading.js";
// Auth0
import { useAuth0 } from "@auth0/auth0-react";
import PrivateRoute from "./PrivateRoute.js";

function App() {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      <PrivateRoute path="/trending-stock" component={SMPage} />
      <PrivateRoute path="/overview" component={Overview} />
      <PrivateRoute path="/crypto-overview" component={CryptoOverview} />
      <PrivateRoute path="/crypto-screener" component={CryptoScreener} />
      <Route path="/faq" component={FAQ} />
      <Route path="/privacy" component={Cookies} />
      <Route path="/cookies" component={Cookies} />
      <Route path="/" component={HomePage} />
    </Switch>
  );
}

export default App;
