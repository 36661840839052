import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class TechnicalAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stock: this.props.stock,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stock !== this.props.stock) {
      this.setState({
        stock: this.props.stock,
      });
    }
    this._ref.current.innerHTML = "";
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      colorTheme: "dark",
      isTransparent: false,
      locale: "en",
      interval: "1D",
      symbol: this.props.stock,
      showIntervalTabs: true,
    });
    this._ref.current.appendChild(script);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      colorTheme: "dark",
      isTransparent: false,
      locale: "en",
      interval: "1D",
      symbol: this.props.stock,
      showIntervalTabs: true,
    });
    this._ref.current.appendChild(script);
  }

  render() {
    const { classes } = this.props;
    this._ref = React.createRef();
    return (
      <div>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.technicalAnalysis}>
              <div className="tradingview-widget-container" ref={this._ref}>
                <div className="tradingview-widget-container__widget"></div>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

TechnicalAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
  stock: PropTypes.string,
};

export default withStyles(productStyle)(TechnicalAnalysis);
