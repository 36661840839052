import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import TradingViewWidget, { Themes, BarStyles } from "react-tradingview-widget";
import Tooltip from "@material-ui/core/Tooltip";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class ADR extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: 14,
        border: "1px solid #dadde9",
      },
    }))(Tooltip);

    const tipText =
      "A ratio of 1 means that there was one advancing stock for every 1 declining stock; above 2 means that the market is in greed mode with 2 stocks rising to every 1 falling.";
    const rule = "NYSE A/D Ratio – < 1 Fear – > 2 Greed";
    return (
      <div className={classes.section}>
        <GridItem xs={12} sm={12} md={12}>
          <HtmlTooltip
            title={<span style={{ whiteSpace: "pre-line" }}>{tipText}</span>}
            placement="right-end"
          >
            <h2 className={classes.title + " " + classes.textCenter}>
              NYSE ADR
            </h2>
          </HtmlTooltip>
          <h4 className={classes.textCenter}>{rule}</h4>
          <div className={classes.ADRChart}>
            <TradingViewWidget
              symbol="INDEX:ADRN"
              theme={Themes.DARK}
              interval="D"
              locale="en"
              width={700}
              height={350}
              toolbar_bg="#f1f3f6"
              style={BarStyles.LINE}
              allow_symbol_change={false}
              details={false}
              hide_side_toolbar={true}
              show_popup_button={false}
            />
          </div>
        </GridItem>
      </div>
    );
  }
}

ADR.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(ADR);
