import React from "react";
import loading from "assets/img/loading.svg";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const Loading = () => (
  <div style={style}>
    <img src={loading} alt="Loading" />
  </div>
);

export default Loading;
