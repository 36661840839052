import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridItem from "components/Grid/GridItem.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class ActiveGainer extends Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      colorTheme: "dark",
      isTransparent: false,
      locale: "en",
      dateRange: "3M",
      exchange: "US",
      showChart: true,
      showSymbolLogo: true,
      plotLineColorGrowing: "rgba(0, 255, 0, 1)",
      plotLineColorFalling: "rgba(255, 0, 0, 1)",
      gridLineColor: "rgba(42, 46, 57, 1)",
      scaleFontColor: "rgba(120, 123, 134, 1)",
      belowLineFillColorGrowing: "rgba(244, 204, 204, 0.12)",
      belowLineFillColorFalling: "rgba(244, 204, 204, 0.12)",
      symbolActiveColor: "rgba(33, 150, 243, 0.12)",
    });
    this._ref.current.appendChild(script);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title + " " + classes.textCenter}>
            Hot Lists
          </h2>
          <div className={classes.marketOverview}>
            <div className="tradingview-widget-container" ref={this._ref}>
              <div className="tradingview-widget-container__widget"></div>
            </div>
          </div>
        </GridItem>
      </div>
    );
  }
}

ActiveGainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(ActiveGainer);
