import React from "react";
// Auth0
import { useAuth0 } from "@auth0/auth0-react";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export const LoginBtn = () => {
  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();
  return (
    <Button
      className={classes.navButton}
      color={window.innerWidth < 960 ? "info" : "info"}
      round
      onClick={() => loginWithRedirect()}
    >
      Login
    </Button>
  );
};
