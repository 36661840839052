import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import axios from "axios";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import configData from "assets/json/config.json";

import CryptoChart from "./cryptochart.js";
import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class CBBI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      picycle: 0.0,
      twoyma: 0.0,
      trololo: 0.0,
      rupl: 0.0,
      puell: 0.0,
      mvrv: 0.0,
      rhdol: 0.0,
      reserveRisk: 0.0,
      woobull: 0.0,
      price: 0.0,
      confidence: 0.0,
      data: [],
    };
    this.getCBBIMetrics = this.getCBBIMetrics.bind(this);
  }

  componentDidMount() {
    this.getCBBIMetrics();
  }

  // Pull data from Cloud Storage
  getCBBIMetrics() {
    axios.get(configData.CBBI_URL, {}).then((res) => {
      const data = res.data;

      let price = data["Price"];
      price = price[Object.keys(price).pop()];
      
      let picycle = data["PiCycle"];
      picycle = Math.ceil(
        parseFloat(picycle[Object.keys(picycle).pop()]) * 100.0
      );
      let twoyma = data["2YMA"];
      twoyma = Math.ceil(parseFloat(twoyma[Object.keys(twoyma).pop()]) * 100.0);
      let trololo = data["Trolololo"];
      trololo = Math.ceil(
        parseFloat(trololo[Object.keys(trololo).pop()]) * 100.0
      );
      let rupl = data["RUPL"];
      rupl = Math.ceil(parseFloat(rupl[Object.keys(rupl).pop()]) * 100.0);
      let puell = data["Puell"];
      puell = Math.ceil(parseFloat(puell[Object.keys(puell).pop()]) * 100.0);
      let mvrv = data["MVRV"];
      mvrv = Math.ceil(parseFloat(mvrv[Object.keys(mvrv).pop()]) * 100.0);
      let rhdol = data["RHODL"];
      rhdol = Math.ceil(parseFloat(rhdol[Object.keys(rhdol).pop()]) * 100.0);
      let reserveRisk = data["ReserveRisk"];
      reserveRisk = Math.ceil(
        parseFloat(reserveRisk[Object.keys(reserveRisk).pop()]) * 100.0
      );
      let woobull = data["Woobull"];
      woobull = Math.ceil(
        parseFloat(woobull[Object.keys(woobull).pop()]) * 100.0
      );
      let confidence = data["Confidence"];
      confidence = Math.ceil(
        parseFloat(confidence[Object.keys(confidence).pop()]) * 100.0
      );

      this.setState({
        price: price,
        confidence: confidence,
        picycle: picycle,
        twoyma: twoyma,
        trololo: trololo,
        rupl: rupl,
        puell: puell,
        mvrv: mvrv,
        rhdol: rhdol,
        reserveRisk: reserveRisk,
        woobull: woobull,
        data: data,
      });
    });
  }

  render() {
    const { classes } = this.props;

    const confidence = this.state.confidence;
    const picycle = this.state.picycle;
    const twoyma = this.state.twoyma;
    const trololo = this.state.trololo;
    const rupl = this.state.rupl;
    const puell = this.state.puell;
    const mvrv = this.state.mvrv;
    const rhdol = this.state.rhdol;
    const reserveRisk = this.state.reserveRisk;
    const woobull = this.state.woobull;
    const today = new Date();

    return (
      <div className={classes.section}>
        <GridItem>
          <h2 className={classes.title + " " + classes.textCenter}>
            CBBI Metrics
          </h2>
          <h4 className={classes.title + " " + classes.textCenter}>
            CONFIDENCE WE ARE AT THE PEAK:
          </h4>
          <h1 className={classes.title + " " + classes.textCenter}>
            {confidence}%
          </h1>
          <h3 className={classes.title + " " + classes.textCenter}>
            {today.getDate() +
              " / " +
              (today.getMonth() + 1) +
              " / " +
              today.getFullYear()}
          </h3>
        </GridItem>
        <GridContainer justifyContent="center">
          <GridItem xs={1}>
            <h3>{picycle}%</h3>
            <p>Pi Cycle Top Indicator</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{twoyma}%</h3>
            <p>2 Year MA</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{trololo}%</h3>
            <p>Bitcoin Trololo Trend Line</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{rupl}%</h3>
            <p>RUPL / NUPL</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{rupl}%</h3>
            <p>RUPL / NUPL</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{puell}%</h3>
            <p>Puell Multiple</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{mvrv}%</h3>
            <p>MVRV Z-Score</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{rhdol}%</h3>
            <p>RHODL Ratio</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{reserveRisk}%</h3>
            <p>Reserve Risk</p>
          </GridItem>
          <GridItem xs={1}>
            <h3>{woobull}%</h3>
            <p>Woobull</p>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <GridItem xs={12}>
            <CryptoChart data={this.state.data} />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CBBI.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(CBBI);
