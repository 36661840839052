/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import "../node_modules/react-vis/dist/style.css";
import { createBrowserHistory } from "history";
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

var hist = createBrowserHistory();

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  hist.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <Router history={hist}>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById("root")
);
