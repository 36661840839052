import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

import axios from "axios";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Card from "components/Card/Card.js";
// import { ReactTinyLink } from "react-tiny-link";
// import Pagination from "components/Pagination/Pagination.js";
import Pagination from "@material-ui/lab/Pagination";
import { Box } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import configData from "assets/json/config.json";

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stock: this.props.stock,
      news: [],
      numShow: 12,
      currentPage: 1,
      totalPages: 0,
      pagesArr: [{ text: "Previous" }],
    };
    this.getNews = this.getNews.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this._ref = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.stock !== this.props.stock) {
      this.getNews(this.props.stock);
      const script = document.createElement("script");
      const ticker = this.props.stock;
      script.innerHTML = `STWT.Widget({
        container: "stocktwits-widget-news",
        symbol: "${ticker}",
        width: "300",
        height: "720",
        limit: "100",
        scrollbars: "true",
        streaming: "true",
        title: "Tweets",
        style: {
          link_color: "4871a8",
          link_hover_color: "4871a8",
          header_text_color: "000000",
          border_color: "cecece",
          divider_color: "cecece",
          divider_type: "solid",
          box_color: "f5f5f5",
          stream_color: "ffffff",
          text_color: "000000",
          time_color: "999999",
        },
      });`;
      var element = document.getElementById("stocktwits-widget-news");
      element.innerHTML = "";
      this._ref.current.appendChild(script);
    }
  }

  handlePageChange = (event, value) => {
    this.setState({
      currentPage: value,
    });
  };

  // Get news
  getNews(stock) {
    axios
      .get(configData.SM_NEWS, {
        params: {
          stock: stock,
        },
      })
      .then((res) => {
        const totalPages = 1;
        var pages = [{ text: "Previous" }];
        for (var i = 1; i <= totalPages; i++) {
          pages.push({ text: i, onClick: this.handlePageChange });
        }
        this.setState({
          news: res.data.stockInfo,
          totalPages: totalPages,
          pagesArr: pages,
        });
      });
  }

  render() {
    const { classes } = this.props;
    const newsRecords = this.state.news;
    const currentPage = this.state.currentPage;
    const numItemsPage = this.state.numShow;
    const numPages = this.state.totalPages;

    return (
      <div>
        <GridContainer>
          <GridItem md={9}>
            <h2 className={classes.title}>Top News</h2>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {newsRecords
                  .slice(
                    (currentPage - 1) * numItemsPage,
                    currentPage * numItemsPage
                  )
                  .map((row) => (
                    <TableRow key={row[0]}>
                      <TableCell component="th" scope="row">
                        <h4 className={classes.cardTitle}>
                          <a
                            href={row[1]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row[0]}
                          </a>
                        </h4>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Box>
              <Pagination
                count={numPages}
                page={currentPage}
                onChange={this.handlePageChange}
                defaultPage={1}
                color="primary"
                size="large"
              />
            </Box>
          </GridItem>
          <GridItem xs={2}>
            <h2 className={classes.title + " " + classes.textCenter}>
              Stocktwits
            </h2>
            <GridContainer>
              <div id="stocktwits-widget-news" ref={this._ref}></div>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

News.propTypes = {
  classes: PropTypes.object.isRequired,
  stock: PropTypes.string,
};

export default withStyles(styles)(News);
