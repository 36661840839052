/*eslint-disable*/
import React, { Component } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Accordion from "components/Accordion/Accordion.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Search Bar
import SearchBar from "material-ui-search-bar";
// TradingView Widget
import TradingViewWidget, { Themes } from "react-tradingview-widget";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyleDup.js";

const useStyles = makeStyles(presentationStyle);

class Cookies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Header
          brand="Albemy"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 200,
            color: "rose",
          }}
        />
        <Parallax
          image={require("assets/img/header.jpg")}
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}></div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.card}>
                <p>
                  This is the Cookie Policy for Albemy, accessible from
                  https://www.albemy.com
                </p>
                <p>
                  <strong>What Are Cookies</strong>
                </p>
                <p>
                  As is common practice with almost all professional websites
                  this site uses cookies, which are tiny files that are
                  downloaded to your computer, to improve your experience. This
                  page describes what information they gather, how we use it and
                  why we sometimes need to store these cookies. We will also
                  share how you can prevent these cookies from being stored
                  however this may downgrade or 'break' certain elements of the
                  sites functionality.
                </p>
                <p>
                  For more general information on cookies, please read{" "}
                  <a href="https://www.cookieconsent.com/what-are-cookies/">
                    "What Are Cookies"
                  </a>
                  . Information regarding cookies from this Cookies Policy are
                  from{" "}
                  <a href="https://www.generateprivacypolicy.com/">
                    the Privacy Policy Generator
                  </a>
                  .
                </p>
                <p>
                  <strong>How We Use Cookies</strong>
                </p>
                <p>
                  We use cookies for a variety of reasons detailed below.
                  Unfortunately in most cases there are no industry standard
                  options for disabling cookies without completely disabling the
                  functionality and features they add to this site. It is
                  recommended that you leave on all cookies if you are not sure
                  whether you need them or not in case they are used to provide
                  a service that you use.
                </p>
                <p>
                  <strong>Disabling Cookies</strong>
                </p>
                <p>
                  You can prevent the setting of cookies by adjusting the
                  settings on your browser (see your browser Help for how to do
                  this). Be aware that disabling cookies will affect the
                  functionality of this and many other websites that you visit.
                  Disabling cookies will usually result in also disabling
                  certain functionality and features of the this site. Therefore
                  it is recommended that you do not disable cookies. This
                  Cookies Policy was created with the help of the{" "}
                  <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">
                    Cookies Policy Generator from CookiePolicyGenerator.com
                  </a>
                  .
                </p>
                <p>
                  <strong>The Cookies We Set</strong>
                </p>
                <ul>
                  <li>
                    <p>Account related cookies</p>
                    <p>
                      If you create an account with us then we will use cookies
                      for the management of the signup process and general
                      administration. These cookies will usually be deleted when
                      you log out however in some cases they may remain
                      afterwards to remember your site preferences when logged
                      out.
                    </p>
                  </li>
                  <li>
                    <p>Login related cookies</p>
                    <p>
                      We use cookies when you are logged in so that we can
                      remember this fact. This prevents you from having to log
                      in every single time you visit a new page. These cookies
                      are typically removed or cleared when you log out to
                      ensure that you can only access restricted features and
                      areas when logged in.
                    </p>
                  </li>
                  <li>
                    <p>Email newsletters related cookies</p>
                    <p>
                      This site offers newsletter or email subscription services
                      and cookies may be used to remember if you are already
                      registered and whether to show certain notifications which
                      might only be valid to subscribed/unsubscribed users.
                    </p>
                  </li>
                  <li>
                    <p>Orders processing related cookies</p>
                    <p>
                      This site offers e-commerce or payment facilities and some
                      cookies are essential to ensure that your order is
                      remembered between pages so that we can process it
                      properly.
                    </p>
                  </li>
                  <li>
                    <p>Surveys related cookies</p>
                    <p>
                      From time to time we offer user surveys and questionnaires
                      to provide you with interesting insights, helpful tools,
                      or to understand our user base more accurately. These
                      surveys may use cookies to remember who has already taken
                      part in a survey or to provide you with accurate results
                      after you change pages.
                    </p>
                  </li>
                  <li>
                    <p>Forms related cookies</p>
                    <p>
                      When you submit data to through a form such as those found
                      on contact pages or comment forms cookies may be set to
                      remember your user details for future correspondence.
                    </p>
                  </li>
                  <li>
                    <p>Site preferences cookies</p>
                    <p>
                      In order to provide you with a great experience on this
                      site we provide the functionality to set your preferences
                      for how this site runs when you use it. In order to
                      remember your preferences we need to set cookies so that
                      this information can be called whenever you interact with
                      a page is affected by your preferences.
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>Third Party Cookies</strong>
                </p>
                <p>
                  In some special cases we also use cookies provided by trusted
                  third parties. The following section details which third party
                  cookies you might encounter through this site.
                </p>
                <ul>
                  <li>
                    <p>
                      This site uses Google Analytics which is one of the most
                      widespread and trusted analytics solution on the web for
                      helping us to understand how you use the site and ways
                      that we can improve your experience. These cookies may
                      track things such as how long you spend on the site and
                      the pages that you visit so we can continue to produce
                      engaging content.
                    </p>
                    <p>
                      For more information on Google Analytics cookies, see the
                      official Google Analytics page.
                    </p>
                  </li>
                  <li>
                    <p>
                      From time to time we test new features and make subtle
                      changes to the way that the site is delivered. When we are
                      still testing new features these cookies may be used to
                      ensure that you receive a consistent experience whilst on
                      the site whilst ensuring we understand which optimisations
                      our users appreciate the most.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Google AdSense service we use to serve advertising
                      uses a DoubleClick cookie to serve more relevant ads
                      across the web and limit the number of times that a given
                      ad is shown to you.
                    </p>
                    <p>
                      For more information on Google AdSense see the official
                      Google AdSense privacy FAQ.
                    </p>
                  </li>
                  <li>
                    <p>
                      Several partners advertise on our behalf and affiliate
                      tracking cookies simply allow us to see if our customers
                      have come to the site through one of our partner sites so
                      that we can credit them appropriately and where applicable
                      allow our affiliate partners to provide any bonus that
                      they may provide you for making a purchase.
                    </p>
                  </li>
                  <li>
                    <p>
                      We also use social media buttons and/or plugins on this
                      site that allow you to connect with your social network in
                      various ways. For these to work the following social media
                      sites including Facebook and Google; will set cookies
                      through our site which may be used to enhance your profile
                      on their site or contribute to the data they hold for
                      various purposes outlined in their respective privacy
                      policies.
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>More Information</strong>
                </p>
                <p>
                  Hopefully that has clarified things for you and as was
                  previously mentioned if there is something that you aren't
                  sure whether you need or not it's usually safer to leave
                  cookies enabled in case it does interact with one of the
                  features you use on our site.
                </p>
                <p>
                  However if you are still looking for more information then you
                  can contact us through one of our preferred contact methods:
                </p>
                <ul>
                  <li>Email: albemybiz@gmail.com</li>
                </ul>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <a
                  href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.footerBrand}
                >
                  Albemy
                </a>
              </div>
              <div className={classes.pullCenter}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#" target="_blank" className={classes.block}>
                      About Albemy
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#" target="_blank" className={classes.block}>
                      Your account
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={classes.block}
                    >
                      Technical Support
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={classes.block}
                    >
                      Terms and Policies
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.rightLinks}>
                <ul>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="twitter"
                      justIcon
                      simple
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="dribbble"
                      justIcon
                      simple
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="instagram"
                      justIcon
                      simple
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
export default withStyles(presentationStyle)(Cookies);
