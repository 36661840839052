import React, { Component } from "react";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class CryptoChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      prices: [],
      confidence: [],
    };
    this.processData = this.processData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });

      this.processData(this.props.data);
    }
  }

  processData(data) {
    var prices = Object.keys(data["Price"]).map((key) => [
      parseInt(key) * 1000,
      data["Price"][key],
    ]);
    var confidence = Object.keys(data["Confidence"]).map((key) => [
      parseInt(key) * 1000,
      data["Confidence"][key],
    ]);
    this.setState({
      prices: prices,
      confidence: confidence,
    });
  }

  render() {
    const options = { style: "currency", currency: "USD" };
    const numberFormat = new Intl.NumberFormat("en-US", options);
    const prices = this.state.prices;
    const confidence = this.state.confidence;

    const configPrice = {
      yAxis: [
        {
          offset: 100,
          // Primary yAxis
          title: {
            text: "BTC Price",
            style: {
              color: "#000",
            },
          },
          labels: {
            formatter: function () {
              return numberFormat.format(this.value);
            },
            x: -15,
            style: {
              color: "#000",
              position: "absolute",
            },
          },
          type: "logarithmic",
        },
        {
          // Secondary yAxis
          title: {
            text: "Confidence",
            style: {
              color: "#000",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#000",
            },
          },
          opposite: false,
        },
      ],
      tooltip: {
        shared: true,
      },
      title: {
        text: `CBBI Historical Chart`,
      },
      subtitle: {
        text: "ColinTalksCrypto Bitcoin Bull Run Index",
      },
      chart: {
        height: 600,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      xAxis: {
        type: "date",
      },
      rangeSelector: {
        buttons: [
          {
            type: "month",
            count: 6,
            text: "6M",
          },
          {
            type: "year",
            count: 1,
            text: "1Y",
          },
          {
            type: "year",
            count: 3,
            text: "3Y",
          },
          {
            type: "year",
            count: 6,
            text: "6Y",
          },
          {
            type: "all",
            text: "All",
          },
        ],
        selected: 4,
      },
      series: [
        {
          name: "Bitcoin Price",
          data: prices,
          tooltip: {
            valueDecimals: 2,
          },
        },
        {
          name: "Confidence Level",
          yAxis: 1,
          data: confidence,
          tooltip: {
            valueDecimals: 2,
          },
        },
      ],
    };
    return (
      <div>
        <ReactHighcharts config={configPrice}></ReactHighcharts>
      </div>
    );
  }
}

CryptoChart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(CryptoChart);
