import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { DataGrid } from "@material-ui/data-grid";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class BuyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buyList: this.props.buyList,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.buyList !== this.props.buyList) {
      this.setState({
        buyList: this.props.buyList,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const columns = [
      { field: "id", headerName: "SYM", sortable: true, width: 110 },
      { field: "adx", headerName: "ADX", sortable: true, width: 110 },
      { field: "trend", headerName: "Trend", sortable: true, width: 200 },
      {
        field: "smatrend",
        headerName: "SMA Trend",
        sortable: true,
        width: 130,
      },
      { field: "rsi", headerName: "RSI", sortable: true, width: 110 },
      { field: "cci", headerName: "CCI", sortable: true, width: 130 },
    ];

    const rows = this.state.buyList;

    return (
      <div className={classes.section}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.title}>Watchlist</h2>
            <div style={{ height: 800, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={12}
                rowsPerPageOptions={[12]}
                onRowSelected={this.props.onChangeValue}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

BuyList.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  buyList: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func,
};

export default withStyles(productStyle)(BuyList);
