import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import axios from "axios";

// core components
import { Grid } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Search from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

// Plot out the charts
// import ReactHighcharts from "react-highcharts/ReactHighstock.src";
// import moment from "moment";
import InfoTable from "./InfoTable.js";
import News from "./News.js";
// import TrendingStocks from "./Trending.js";
import BuyList from "./Buylist.js";
// import CompanyInfo from "./CompanyInfo.js";
import TechnicalAnalysis from "./TechnicalAnalysis.js";
import TradingViewWidget, { Themes, BarStyles } from "react-tradingview-widget";
import configData from "assets/json/config.json";

class SMPlots extends Component {
  constructor(props) {
    super(props);

    // Search bar text input
    this.searchBarRef = React.createRef();

    this.state = {
      stock: "",
      stockName: "",
      stockHistPrice: [],
      trendingStocks: [],
      buyList: [],
    };
    this.updateSymbol = this.updateSymbol.bind(this);
    this.getTrendingStocks = this.getTrendingStocks.bind(this);
    this.getBuyLists = this.getBuyLists.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
  }

  componentDidMount() {
    // this.getTrendingStocks();
    this.getBuyLists();
  }

  // Pull data from Cloud Storage
  getTrendingStocks() {
    axios.get(configData.GS_TRENDING_WSB_6H_URL, {}).then((res) => {
      const data = res.data.data;
      var trendingStocks = [];
      const topStock = data[0][0];
      data.forEach((d) =>
        trendingStocks.push({
          id: d[0],
          score: d[1][0],
          prev: d[1][1],
          change: d[1][2],
          rockets: d[1][3],
          price: d[1][4],
          dayChange: d[1][5],
          fiftyDayChange: d[1][6],
          changeVol: d[1][7],
          float: d[1][8],
          floatpercent: d[1][9],
          industry: d[1][10],
        })
      );
      this.setState({
        trendingStocks: trendingStocks, // Update data for trending stock List
        stock: topStock,
      });
    });
  }

  // Pull data from Cloud Storage
  getBuyLists() {
    axios.get(configData.GS_BUYLIST_URL, {}).then((res) => {
      const data = res.data.data;
      var buylist = [];
      const topStock = data[0]["Symbol"];
      data.forEach((d) =>
        buylist.push({
          id: d["Symbol"],
          adx: (Math.round(d["ADX"] * 100) / 100).toFixed(2),
          trend: d["Trend"],
          smatrend: d["SMA Trend"],
          rsi: d["RSI"],
          cci: d["CCI"],
        })
      );
      this.setState({
        buyList: buylist, // Update data for buy List
        stock: topStock,
      });
    });
  }

  updateSymbol(e) {
    e.preventDefault();
    let query = this.searchBarRef.current.value;
    this.setState(() => ({
      stock: query, // Update data for plot history price
    }));
  }

  handleChangeValue(e) {
    console.log(e);
    this.setState(() => ({
      stock: e.data.id, // Update data for plot history price
    }));
  }

  render() {
    // const options = { style: "currency", currency: "USD" };
    // const numberFormat = new Intl.NumberFormat("en-US", options);
    const { classes } = this.props;
    const chartURL = `https://charts2.finviz.com/chart.ashx?ty=c&ta=1&p=d&s=l&t=${this.state.stock}`;

    return (
      <div className={classes.section}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={6}>
            <BuyList
              buyList={this.state.buyList}
              onChangeValue={this.handleChangeValue}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.section}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.searchBar}>
                    <form>
                      <TextField
                        inputRef={this.searchBarRef}
                        variant="outlined"
                        placeholder="Search another Symbol"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                type="submit"
                                onClick={this.updateSymbol}
                              >
                                <Search />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TechnicalAnalysis stock={this.state.stock} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <img src={chartURL} className={classes.finvizChart} alt="" />
                </GridItem>
              </GridContainer>
              <GridContainer></GridContainer>
            </div>
          </GridItem>
        </Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.description}>{this.state.stock}</h3>
            <TradingViewWidget
              symbol={this.state.stock}
              theme={Themes.DARK}
              interval="D"
              locale="en"
              width={1650}
              height={700}
              toolbar_bg="#f1f3f6"
              style={BarStyles.HEIKIN_ASHI}
              allow_symbol_change={true}
              details={true}
              studies={["MASimple@tv-basicstudies", "RSI@tv-basicstudies"]}
              hide_side_toolbar={true}
              show_popup_button={true}
              popup_width="1980"
              popup_height="1080"
            />
            {/*<ReactHighcharts config={configPrice}></ReactHighcharts>*/}
          </GridItem>
          {/*{" "}*/}
          <GridItem xs={12} sm={4} md={4}>
            {/*
            <CompanyInfo stock={this.state.stock}></CompanyInfo>
            <TechnicalAnalysis stock={this.state.stock}></TechnicalAnalysis>
            {" "}
            */}
          </GridItem>
        </GridContainer>
        <InfoTable stock={this.state.stock} />
        <News stock={this.state.stock} />
      </div>
    );
  }
}

SMPlots.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
};

export default withStyles(productStyle)(SMPlots);
