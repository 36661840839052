/*eslint-disable*/
import React, { Component } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Accordion from "components/Accordion/Accordion.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Search Bar
import SearchBar from "material-ui-search-bar";
// TradingView Widget
import TradingViewWidget, { Themes } from "react-tradingview-widget";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "views/LoginPage/Loading";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyleDup.js";

// Sections for this page
import MarketOverview from "./Sections/marketoverview.js";
import Calendar from "./Sections/economiccalendar.js";
import ActiveGainer from "./Sections/activeGainer.js";
import Timeline from "./Sections/timeline.js";
import TechnicalAnalysis from "./Sections/TechnicalAnalysis.js";
import ADR from "./Sections/ADR.js";
import CBOE from "./Sections/cboe.js";
import FearGreedIndex from "./Sections/cnnfeargreed.js";

const useStyles = makeStyles(presentationStyle);

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Header
          brand="Albemy"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 50,
            color: "rose",
          }}
        />
        <Parallax
          image={require("assets/img/header.jpg")}
          className={classes.parallax}
        ></Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <GridContainer justifyContent="center">
                  <FearGreedIndex />
                </GridContainer>
              </Grid>
              <Grid item xs={12}>
                <GridContainer justifyContent="center">
                  <TechnicalAnalysis ticker="SPCFD:SPX" />
                  <TechnicalAnalysis ticker="DJCFD:DJI" />
                  <TechnicalAnalysis ticker="NASDAQ:NDX" />
                </GridContainer>
              </Grid>
              <Grid item xs={12}>
                <GridContainer justifyContent="center">
                  <CBOE />
                  <ADR />
                </GridContainer>
              </Grid>
            </Grid>
          </div>
          <div className={classes.container}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <GridContainer justifyContent="center">
                  <MarketOverview />
                  <ActiveGainer />
                  <Calendar />
                </GridContainer>
              </Grid>
              <Grid style={{ height: "100%" }}>
                <GridContainer justifyContent="center">
                  <Timeline />
                </GridContainer>
              </Grid>
            </Grid>
          </div>
        </div>
        <Footer
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <a
                  href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.footerBrand}
                >
                  Albemy
                </a>
              </div>
              <div className={classes.pullCenter}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#" target="_blank" className={classes.block}>
                      About Albemy
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#" target="_blank" className={classes.block}>
                      Your account
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={classes.block}
                    >
                      Technical Support
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={classes.block}
                    >
                      Terms and Policies
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.rightLinks}>
                <ul>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="twitter"
                      justIcon
                      simple
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="dribbble"
                      justIcon
                      simple
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="instagram"
                      justIcon
                      simple
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
export default withStyles(presentationStyle)(Overview);
