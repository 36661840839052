import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridItem from "components/Grid/GridItem.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class Timeline extends Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      colorTheme: "dark",
      isTransparent: false,
      locale: "en",
      displayMode: "regular",
    });
    this._ref.current.appendChild(script);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title + " " + classes.textCenter}>
            Trending Events
          </h2>
          <div className={classes.timeline}>
            <div className="tradingview-widget-container" ref={this._ref}>
              <div className="tradingview-widget-container__widget"></div>
            </div>
          </div>
        </GridItem>
      </div>
    );
  }
}

Timeline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(Timeline);
