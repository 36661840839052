import {
  title,
  main,
  whiteColor,
  grayColor,
  mainRaised,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const presentationStyleDup = {
  ...footerStyle,
  main: {
    ...main,
    /*overflow: "hidden"*/
  },
  mainRaised,
  parallax: {
    height: "15vh",
    overflow: "hidden",
  },
  container: {
    position: "relative",
    padding: "5px 80px 5px 80px",
    zIndex: 1,
  },
  chartsContainer: {
    position: "relative",
    padding: "5px 5px 2px 5px",
    zIndex: 1,
    margin: "0 auto",
    width: "10vh",
    height: "10vh",
  },
  title: {
    ...title,
    color: grayColor,
    textAlign: "center",
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  card: {
    marginTop: "2vw",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    paddingBottom: "2vw",
  },
  formControl: {
    margin: "0",
    padding: "8px 0 0 0",
  },
  qna: {
    marginBottom: "4vw",
  },
  searchBtn: {
    marginLeft: "1vw",
  },
  proBadge: {
    position: "relative",
    fontSize: "22px",
    textTransform: "uppercase",
    fontWeight: "700",
    right: "-10px",
    padding: "10px 18px",
    top: "-30px",
    background: whiteColor,
    borderRadius: "3px",
    color: grayColor[18],
    lineHeight: "22px",
    boxShadow: "0 5px 5px -2px rgba(" + hexToRgb(grayColor[25]) + ",.4)",
  },
};

export default presentationStyleDup;
