import { title, grayColor } from "assets/jss/material-kit-pro-react.js";

const productStyle = {
  section: {
    padding: "5px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "10px",
    minHeight: "32px",
    textDecoration: "none",
  },
  description: {
    color: grayColor[0],
  },
  fearGreed: {
    position: "relative",
    padding: "5px 5px 5px 5px",
    width: "80vh",
    height: "25vh",
  },
  marketOverview: {
    position: "relative",
    padding: "5px 5px 5px 5px",
    width: "40vh",
    height: "70vh",
  },
  economicCalendar: {
    position: "relative",
    padding: "5px 5px 5px 5px",
    width: "80vh",
    height: "57vh",
  },
  timeline: {
    position: "relative",
    padding: "5px 5px 5px 5px",
    width: "65vh",
    height: "140vh",
  },
  technicalAnalysis: {
    position: "relative",
    padding: "5px 5px 10px 5px",
    width: "40vh",
    height: "42vh",
    textAlign: "center",
    margin: "auto",
  },
  ADRChart: {
    position: "relative",
    padding: "5px 5px 5px 5px",
    width: "60vh",
    height: "38vh",
  },
  finvizChart: {
    maxWidth: "95%",
    height: "auto",
  },
  searchBar: {
    textAlign: "center",
    margin: "auto",
    width: "60%",
  },
};

export default productStyle;
