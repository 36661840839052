import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";
import Iframe from "react-iframe";
import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class CBOE extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: 14,
        border: "1px solid #dadde9",
      },
    }))(Tooltip);

    const tipText =
      "A low VIX price indicates the market is good, and stock prices will continue on their normal upward trajectory. \n\n A high VIX price above 30 indicates that more Put contacts are being purchased and that the investors are fearful and covering their trades with downside insurance.";
    const rule = "CBOE Volatility Index – >30 = Fear – < 20 = Greed";
    return (
      <div className={classes.section}>
        <GridItem xs={12} sm={12} md={12}>
          <HtmlTooltip
            title={<span style={{ whiteSpace: "pre-line" }}>{tipText}</span>}
            placement="right-end"
          >
            <h2 className={classes.title + " " + classes.textCenter}>
              CBOE VIX
            </h2>
          </HtmlTooltip>
          <h4 className={classes.textCenter}>{rule}</h4>
          <div className={classes.ADRChart}>
            <Iframe
              url="https://fred.stlouisfed.org/graph/graph-landing.php?g=E6zi&width=560&height=300"
              position="relative"
              width="100%"
              height="100%"
            ></Iframe>
          </div>
        </GridItem>
      </div>
    );
  }
}

CBOE.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(CBOE);
