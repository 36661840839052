import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridItem from "components/Grid/GridItem.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class MarketOverview extends Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      tabs: [
        {
          title: "Indices",
          symbols: [
            {
              s: "FOREXCOM:SPXUSD",
              d: "S&P 500",
            },
            {
              s: "FOREXCOM:NSXUSD",
              d: "Nasdaq 100",
            },
            {
              s: "FOREXCOM:DJI",
              d: "Dow 30",
            },
            {
              s: "INDEX:NKY",
              d: "Nikkei 225",
            },
            {
              s: "INDEX:DEU30",
              d: "DAX Index",
            },
            {
              s: "FOREXCOM:UKXGBP",
              d: "UK 100",
            },
          ],
          originalTitle: "Indices",
        },
        {
          title: "Commodities",
          symbols: [
            {
              s: "CME_MINI:ES1!",
              d: "S&P 500",
            },
            {
              s: "CME:6E1!",
              d: "Euro",
            },
            {
              s: "COMEX:GC1!",
              d: "Gold",
            },
            {
              s: "NYMEX:CL1!",
              d: "Crude Oil",
            },
            {
              s: "NYMEX:NG1!",
              d: "Natural Gas",
            },
            {
              s: "CBOT:ZC1!",
              d: "Corn",
            },
          ],
          originalTitle: "Commodities",
        },
      ],
      showSymbolLogo: true,
      colorTheme: "dark",
      isTransparent: false,
      locale: "en",
      dateRange: "3M",
      showChart: true,
      plotLineColorGrowing: "rgba(0, 255, 0, 1)",
      plotLineColorFalling: "rgba(255, 0, 0, 1)",
      gridLineColor: "rgba(42, 46, 57, 1)",
      scaleFontColor: "rgba(120, 123, 134, 1)",
      belowLineFillColorGrowing: "rgba(244, 204, 204, 0.12)",
      belowLineFillColorFalling: "rgba(244, 204, 204, 0.12)",
      symbolActiveColor: "rgba(33, 150, 243, 0.12)",
    });
    this._ref.current.appendChild(script);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title + " " + classes.textCenter}>
            Market Overview
          </h2>
          <div className={classes.marketOverview}>
            <div className="tradingview-widget-container" ref={this._ref}>
              <div className="tradingview-widget-container__widget"></div>
            </div>
          </div>
        </GridItem>
      </div>
    );
  }
}

MarketOverview.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(MarketOverview);
