/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Share from "@material-ui/icons/Share";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// Cookies usage
import CookieConsent from "react-cookie-consent";

// sections for this page
import SectionDescription from "views/HomePage/Sections/SectionDescription.js";
import SectionContactUs from "views/HomePage/Sections/SectionContactUs.js";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Albemy"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "rose",
        }}
      />
      <Parallax
        image={require("assets/img/home/home_header.jpg")}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1>Albemy</h1>
                <h3 className={classes.title}>
                  One-stop portal to keep updated with trending stocks
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
        <SectionContactUs />
      </div>
      <div>
        <div>
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{
              background: "#70af85",
              color: "white",
              fontWeight: "bolder",
              borderRadius: "1vh",
            }}
            declineButtonStyle={{
              background: "#ef4f4f",
              color: "white",
              fontWeight: "bolder",
              borderRadius: "1vh",
            }}
            expires={150}
          >
            <h5>
              Albemy uses cookies to enhance your browsing experience, analyze
              traffic and serve targeted ads. By continuing, you agree to our{" "}
              <Link
                to="/privacy"
                className={classes.dropdownLink}
                style={{ color: "#f05454" }}
              >
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link
                to="/cookies"
                className={classes.dropdownLink}
                style={{ color: "#f05454" }}
              >
                Cookies Policy
              </Link>
              .
            </h5>
          </CookieConsent>
        </div>
      </div>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a
                href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-footer-components"
                target="_blank"
                className={classes.footerBrand}
              >
                Albemy
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="#" target="_blank" className={classes.block}>
                    About Albemy
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="#" target="_blank" className={classes.block}>
                    Your account
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className={classes.block}
                  >
                    Technical Support
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className={classes.block}
                  >
                    Terms and Policies
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="#"
                    target="_blank"
                    color="twitter"
                    justIcon
                    simple
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="#"
                    target="_blank"
                    color="dribbble"
                    justIcon
                    simple
                  >
                    <i className="fab fa-dribbble" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="#"
                    target="_blank"
                    color="instagram"
                    justIcon
                    simple
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}
