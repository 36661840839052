import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import ReactSpeedometer from "react-d3-speedometer";

// core components
import GridItem from "components/Grid/GridItem.js";
import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

import configData from "assets/json/config.json";

class FearGreedIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feargreedval: "",
      description: "",
      last_updated: "",
    };
    this.getfeargreed = this.getfeargreed.bind(this);
  }

  componentDidMount() {
    this.getfeargreed();
  }

  getfeargreed() {
    axios.get(configData.FEAR_GREED_URL, {}).then((res) => {
      const data = res.data;
      this.setState({
        feargreedval: parseFloat(data.fngval).toFixed(0),
        description: data.description,
        last_updated: data.last_update,
      });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title + " " + classes.textCenter}>
            CNN Fear Greed Index
          </h2>
          <div>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Last Updated: {this.state.last_updated}</h4>
              <ReactSpeedometer
                width={400}
                needleHeightRatio={0.8}
                value={parseInt(this.state.feargreedval)}
                currentValueText="Fear and Greed Index"
                minValue={0}
                maxValue={100}
                customSegmentLabels={[
                  {
                    text: "Extreme Fear",
                    position: "INSIDE",
                    color: "#555",
                  },
                  {
                    text: "Fear",
                    position: "INSIDE",
                    color: "#555",
                  },
                  {
                    text: "Neutral",
                    position: "INSIDE",
                    color: "#555",
                  },
                  {
                    text: "Greed",
                    position: "INSIDE",
                    color: "#555",
                  },
                  {
                    text: "Extreme Greed",
                    position: "INSIDE",
                    color: "#555",
                  },
                ]}
                ringWidth={40}
                needleTransitionDuration={3000}
                needleTransition="easeElastic"
                needleColor={"#6e649b"}
                textColor={"#d8dee9"}
              />
            </GridItem>
          </div>
        </GridItem>
      </div>
    );
  }
}

FearGreedIndex.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(FearGreedIndex);
