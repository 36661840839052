/*eslint-disable*/
import React, { Component } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import axios from "axios";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Accordion from "components/Accordion/Accordion.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "views/LoginPage/Loading";
import { DataGrid } from "@mui/x-data-grid";
import configData from "assets/json/config.json";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyleDup.js";

const useStyles = makeStyles(presentationStyle);

class CoinGeckoMasterTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cryptolist: [],
    };
  }

  componentDidMount() {
    axios.get(configData.GS_COINGECKO_MASTERCSV_URL, {}).then((res) => {
      const data = res.data.data;

      var cryptolist = [];
      data.forEach((d) =>
        cryptolist.push({
          id: d["market_cap_rank"],
          icon: d["image"],
          asset: d["name"],
          symbol: d["symbol"].toUpperCase(),
          current_price: "$" + d["current_price"],
          price_change_percentage_24h:
            (Math.round(d["price_change_percentage_24h"] * 100) / 100).toFixed(
              2
            ) + "%",
          mcap: "$" + d["market_cap"],
          total_volume: d["total_volume"],
          circulating_supply: d["circulating_supply"],
          total_supply: d["total_supply"],
          ath_atl: d["ath"] + " / " + d["atl"],
          percent_ath: d["ath_change_percentage"],
          percent_atl: d["atl_change_percentage"],
          likes: d["likes"],
          category: d["category"],
        })
      );
      this.setState({
        cryptolist: cryptolist,
      });
    });
  }

  render() {
    const { classes } = this.props;
    const columns = [
      { field: "id", headerName: "#", sortable: true, width: 80 },
      {
        field: "icon",
        headerName: "Icon",
        renderCell: (params) => (
          <img src={params.value} width="40%" height="50%" />
        ),
        width: 100,
      },
      { field: "asset", headerName: "Asset", sortable: true, width: 150 },
      { field: "symbol", headerName: "Symbol", sortable: true, width: 100 },
      {
        field: "current_price",
        headerName: "Price(USD)",
        sortable: true,
        width: 130,
      },
      {
        field: "price_change_percentage_24h",
        headerName: "% Change(D)",
        sortable: true,
        width: 130,
      },
      {
        field: "mcap",
        headerName: "Market Cap",
        sortable: true,
        width: 130,
      },
      {
        field: "total_volume",
        headerName: "Total Volume",
        sortable: true,
        width: 130,
      },
      {
        field: "circulating_supply",
        headerName: "Circulating Supply",
        sortable: true,
        width: 150,
      },
      {
        field: "total_supply",
        headerName: "Total Supply",
        sortable: true,
        width: 130,
      },
      {
        field: "ath_atl",
        headerName: "ATH/ATL",
        sortable: false,
        width: 130,
      },
      {
        field: "percent_ath",
        headerName: "%ATH",
        sortable: true,
        width: 130,
      },
      {
        field: "percent_atl",
        headerName: "%ATL",
        sortable: true,
        width: 130,
      },
      {
        field: "likes",
        headerName: "Likes",
        sortable: true,
        width: 130,
      },
      {
        field: "category",
        headerName: "Categories",
        sortable: true,
        width: 500,
      },
    ];

    const rows = this.state.cryptolist;

    return (
      <div className={classes.section}>
        <GridItem>
          <h2 className={classes.title + " " + classes.textCenter}>
            CoinGecko Screnner
          </h2>
        </GridItem>
        <GridContainer justifyContent="center">
          <GridItem xs={12} lg={12}>
            <div style={{ height: 800, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                onRowSelected={this.props.onChangeValue}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(presentationStyle)(CoinGeckoMasterTable);
