import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "95%",
      colorTheme: "dark",
      isTransparent: false,
      locale: "en",
      importanceFilter: "0,1",
      currencyFilter: "USD",
    });
    this._ref.current.appendChild(script);
  }

  render() {
    const { classes } = this.props;

    const tipText =
      "\u2022 Gross Domestic Product (GDP) \t \u2022 Consumer Confidence Survey \t \u2022 Retail Sales Report \n \u2022 Consumer Price Index (CPI) \t \u2022 Producer Price Index (PPI) \t \u2022 U.S. Housing Data \n \u2022 U.S. Non-farm Payroll (NFP) \t \u2022 Federal Open Market Committee (FOMC) \t \u2022 U.S. Oil Data";

    return (
      <div className={classes.section}>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title + " " + classes.textCenter}>
            Economic Calendar
          </h2>
          <h5 style={{ whiteSpace: "pre-wrap" }}>{tipText}</h5>
          <div className={classes.economicCalendar}>
            <div className="tradingview-widget-container" ref={this._ref}>
              <div className="tradingview-widget-container__widget"></div>
            </div>
          </div>
        </GridItem>
      </div>
    );
  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(Calendar);
