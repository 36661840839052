/*eslint-disable*/
import React, { Component } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import AccordionModified from "components/Accordion/Accordion.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Search Bar
import SearchBar from "material-ui-search-bar";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyleDup.js";

const useStyles = makeStyles(presentationStyle);

class FAQPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [
        {
          title: "Who is Albemy for?",
          content: (
            <h5>
              Albemy is a tool for you to do your DD on potential stocks. We
              pull in data from multiple sites to allow you to have an overview
              on the market conditions, company profile etc.
            </h5>
          ),
        },
        {
          title: "How does Albemy works?",
          content: (
            <div className="row">
              <h5>
                Albemy brings you the latest updates to the trending stocks. At
                a glance, you can easily identify the top performing stocks and
                add them to your portfolio.
              </h5>
              <h5>
                At Albemy, we provide you the tools to do your own due diligence
                to maximise your portfolio performance."
              </h5>
            </div>
          ),
        },
        {
          title: "What do I need to get started?",
          content: (
            <div className="row">
              <h5>
                All you need is an email address or an account on any of the
                social media platforms listed.
              </h5>
              <h5>Register for an account now.</h5>
            </div>
          ),
        },
        {
          title: "How much will it cost me?",
          content: <h5>Contact us at albemybiz@gmail.com.</h5>,
        },
      ],
      questionsVisible: [
        {
          title: "Who is Albemy for?",
          content: (
            <h5>
              Albemy is a tool for you to do your DD on potential stocks. We
              pull in data from multiple sites to allow you to have an overview
              on the market conditions, company profile etc.
            </h5>
          ),
        },
        {
          title: "How does Albemy works?",
          content: (
            <div className="row">
              <h5>
                Albemy brings you the latest updates to the trending stocks. At
                a glance, you can easily identify the top performing stocks and
                add them to your portfolio.
              </h5>
              <h5>
                At Albemy, we provide you the tools to do your own due diligence
                to maximise your portfolio performance."
              </h5>
            </div>
          ),
        },
        {
          title: "What do I need to get started?",
          content: (
            <div className="row">
              <h5>
                All you need is an email address or an account on any of the
                social media platforms listed.
              </h5>
              <h5>Register for an account now.</h5>
            </div>
          ),
        },
        {
          title: "How much will it cost me",
          content: <h5>Contact us at albemybiz@gmail.com.</h5>,
        },
      ],
      questionTitles: [
        "Who is Albemy for",
        "How does Albemy works",
        "What do I need to get started",
        "How much will it cost me",
      ],
    };
    this.filterResults = this.filterResults.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  filterResults(value) {
    let qns = this.state.questions;
    // Init qns seen to be empty
    let qnsVisible = [];
    // Is search bar is empty
    if (value === "") {
      this.setState({
        questionsVisible: qns,
      });
    } else {
      // Parse search term and compare with titles
      let titles = this.state.questionTitles;
      for (let i = 0; i < titles.length; i++) {
        if (titles[i].includes(value)) {
          qnsVisible.push(qns[i]);
        }
      }
      this.setState({
        questionsVisible: qnsVisible,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const questionsVisible = this.state.questionsVisible;
    return (
      <div>
        <Header
          brand="Albemy"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 200,
            color: "rose",
          }}
        />
        <Parallax
          image={require("assets/img/header.jpg")}
          className={classes.parallax}
        >
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h2 className={classes.title}>Commonly Asked Questions</h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.qna}>
                  {questionsVisible.length ? (
                    <AccordionModified
                      activeColor="rose"
                      collapses={questionsVisible}
                    />
                  ) : (
                    <h3>
                      No search results, kindly contact us for more info.{" "}
                    </h3>
                  )}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <a
                  href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.footerBrand}
                >
                  Albemy
                </a>
              </div>
              <div className={classes.pullCenter}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#" target="_blank" className={classes.block}>
                      About Albemy
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#" target="_blank" className={classes.block}>
                      Your account
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={classes.block}
                    >
                      Technical Support
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={classes.block}
                    >
                      Terms and Policies
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.rightLinks}>
                <ul>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="twitter"
                      justIcon
                      simple
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="dribbble"
                      justIcon
                      simple
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#"
                      target="_blank"
                      color="instagram"
                      justIcon
                      simple
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
export default withStyles(presentationStyle)(FAQPage);
