import React from "react";
// Auth0
import { useAuth0 } from "@auth0/auth0-react";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

var returnURL = "";
if (process.env.NODE_ENV === "development") {
  returnURL = "http://localhost:3000";
} else if (process.env.NODE_ENV === "production") {
  returnURL = "https://www.albemy.com";
}

export const LogoutBtn = () => {
  const { logout } = useAuth0();
  const classes = useStyles();
  return (
    <Button
      className={classes.navButton}
      color={window.innerWidth < 960 ? "danger" : "danger"}
      round
      onClick={() =>
        logout({
          returnTo: returnURL,
        })
      }
    >
      Logout
    </Button>
  );
};
